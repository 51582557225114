import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { IPageGuard } from 'types/PageGuard'
import { useUserSession } from 'hooks'

/*
 * If not a guest, then redirect to the home page
 */

const withGuestPage =
  ({ unauthorizedUrl = '/' }: IPageGuard = {}) =>
  <P extends object>(Component: React.ComponentType<P>) => {
    const ComponentWithGuestPage = (props: P) => {
      const router = useRouter()
      const { isGuest, sessionLoading, sessionAccountId, securityCheck } = useUserSession()

      useEffect(() => {
        const loadData = async () => {
          if (!router.isReady || sessionLoading) return

          // URLs that are allowed for guests to signup, immune to redirect when isGuest is set to `false`
          const signupUrls = ['/signup/wholesaler', '/signup/buyer', '/signup/sales-rep']
          const guestOK = !(!isGuest && !signupUrls.includes(router.asPath))

          securityCheck({ positiveCondition: guestOK, unauthorizedUrl, recordReturnTo: false, returnToIfOK: false })
        }

        loadData()
      }, [router.isReady, isGuest, sessionLoading, router.asPath, securityCheck])

      return sessionAccountId || sessionLoading || !isGuest ? null : <Component {...props} />
    }

    return ComponentWithGuestPage
  }

export default withGuestPage
