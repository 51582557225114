import React, { useEffect } from 'react'

import { useRouter } from 'next/router'

import { IPageGuard } from 'types/PageGuard'
import { useUserSession } from 'hooks'

/*
 * If not current session, then redirect to the login page
 */
const withBuyerPage =
  ({ unauthorizedUrl = '/login' }: IPageGuard = {}) =>
  <P extends object>(Component: React.ComponentType<P>) => {
    const ComponentWithSecurePage = (props: P) => {
      const router = useRouter()
      const { isBuyer, sessionLoading, isGuest, sessionAccountId, securityCheck } = useUserSession()

      useEffect(() => {
        const loadData = async () => {
          if (!router.isReady || sessionLoading) return
          securityCheck({ positiveCondition: Boolean(isBuyer), unauthorizedUrl })
        }

        loadData()
      }, [isBuyer, router.asPath, router.isReady, router.query.returnTo, sessionLoading, securityCheck])

      return !sessionAccountId || sessionLoading || isGuest ? null : <Component {...props} />
    }

    return ComponentWithSecurePage
  }

export default withBuyerPage
