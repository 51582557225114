import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useUserSession } from 'hooks'
import GET_BUYER_PRODUCT_CATEGORY_PREFERENCES from 'modules/signup/pages/SelectProductCategoryPreferencesPage/graphql/GetBuyerProductCategoryPreferences.graphql'
import { GetBuyerProductCategoryPreferences } from 'modules/signup/pages/SelectProductCategoryPreferencesPage/graphql/__generated__/GetBuyerProductCategoryPreferences'

import GET_REP_PRODUCT_CATEGORY_PREFERENCES from 'modules/signup/pages/CreateRepProductCategoriesPage/graphql/GetRepProductCategoryPreferences.graphql'
import { GetRepProductCategoryPreferences } from 'modules/signup/pages/CreateRepProductCategoriesPage/graphql/__generated__/GetRepProductCategoryPreferences'
import { BusinessCategoryTaxonomySourceEnum, RepKindEnum } from '../../__generated__/globalTypes'

/*
 * If there are no product category preferences, then redirect to the select-product-category-preferences page
 */
const withProductCategoryPreferencesPage = <P extends object>(Component: React.ComponentType<P>) => {
  const ComponentOfThePage = (props: P) => {
    const router = useRouter()
    const { isBuyer, isRep } = useUserSession()
    const [getBuyerProductCategoryPreferences] = useLazyQuery<GetBuyerProductCategoryPreferences>(
      GET_BUYER_PRODUCT_CATEGORY_PREFERENCES
    )

    const [getRepProductCategoryPreferences] = useLazyQuery<GetRepProductCategoryPreferences>(
      GET_REP_PRODUCT_CATEGORY_PREFERENCES
    )

    useEffect(() => {
      ;(async () => {
        if (isBuyer) {
          const buyerWasInvited = Boolean(router.query.redirectTo)

          const buyerProductCategoryPreferences = await getBuyerProductCategoryPreferences()
          const hasProductCategory =
            buyerProductCategoryPreferences?.data?.currentBuyer?.productCategoryTaxonomies?.length
          const hasBrandValues = buyerProductCategoryPreferences?.data?.currentBuyer?.brandValues?.length
          const hasBusinessCategory = buyerProductCategoryPreferences?.data?.currentBuyer?.businessCategoryTaxonomyId
          const businessCategoryTaxonomySource =
            buyerProductCategoryPreferences?.data?.currentBuyer.businessCategoryTaxonomySource
          const hasDescription = buyerProductCategoryPreferences?.data?.currentBuyer?.description
          const helpGuideCompleted = buyerProductCategoryPreferences?.data?.currentBuyer?.helpGuideCompleted

          if (!hasProductCategory) {
            if (buyerWasInvited) {
              return router.push({
                pathname: '/create-account/select-product-category-preferences',
                query: {
                  redirectTo: router.query.redirectTo
                }
              })
            } else {
              // Disabling this for now. Not sure it's needed? If anything, we should only be redirecting buyers to this page if they have never used recommendations before
              //
              // return router.push({
              //   pathname: '/recommended-sellers',
              //   query: {
              //     skipLanding: true
              //   }
              // })
            }
          }

          const redirectToAddBusinessCategory =
            helpGuideCompleted &&
            hasProductCategory &&
            hasBrandValues &&
            (!hasBusinessCategory ||
              !hasDescription ||
              (hasBusinessCategory && businessCategoryTaxonomySource !== BusinessCategoryTaxonomySourceEnum.USER))

          // Redirect to 'add-business-category' page only after the buyer completes the help guide,
          // and accomplished product category and brand values. Also redirect if the buyer's business category taxonomy source is not USER
          if (redirectToAddBusinessCategory) {
            router.push({
              pathname: '/bh/account/add-business-category'
            })
          }
        } else if (isRep) {
          const repProductCategoryPreferences = await getRepProductCategoryPreferences()
          const currentRep = repProductCategoryPreferences?.data?.currentRep

          if (currentRep?.kind === RepKindEnum.EMPLOYEE) return

          if (currentRep?.kind === RepKindEnum.INDEPENDENT && !currentRep?.profileCompleted) {
            return router.push({ pathname: '/create-account/sales-rep/preview' })
          } else if (!repProductCategoryPreferences?.data?.currentRep?.productCategoryTaxonomies?.length) {
            return router.push({
              pathname: '/create-account/sales-rep/product-categories',
              query: {
                updateProductTaxonomy: true
              }
            })
          }
        }
      })()
    }, [getBuyerProductCategoryPreferences, getRepProductCategoryPreferences, isBuyer, isRep, router])

    return <Component {...props} />
  }

  return ComponentOfThePage
}

export default withProductCategoryPreferencesPage
